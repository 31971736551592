[class^='gjs-sm'] {
	font-size: 14px !important;
	font-weight: 400 !important;
	font-family: var(--primaryFont) !important;
	color: var(--primaryTextColor);
}

.gjs-sm-sector-title .gjs-sm-property {
	margin: 7px auto;
}

.gjs-sm-sectors {
	background-color: white !important;
	font-weight: 400 !important;
}

.gjs-sm-sector {
	background-color: var(--secondaryColor);
	border: none !important;
	margin: 4px;
	border-radius: 8px;
}

.gjs-sm-sector-title {
	background-color: transparent;
	border: none;
	padding: 8px 12px !important;
}

.gjs-sm-sector-label {
	font-weight: 500 !important;
}

.gjs-sm-sector-caret {
	float: right;
	position: absolute;
	right: 0px;
	transform: rotate(90deg);
}

.gjs-sm-label {
	align-items: stretch !important;
}

.gjs-sm-layer.gjs-sm-active {
	background-color: var(--secondaryColor);
}

.gjs-sm-btn-close path,
.gjs-sm-field.gjs-sm-stack path {
	fill: #454259;
	opacity: 0.5;
}

.gjs-sm-layer .gjs-sm-label-wrp {
	align-items: baseline;
}
