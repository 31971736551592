:root {
	/* theme */
	--bs-primary: #7f6df2;
	--bs-primary-rgb: 112, 93, 239;
	--primaryColor: #7f6df2;
	--secondaryColor: #f4f4f7;

	/* Gray Colors*/
	--lightGrayColor: #afadb966;
	--grayColor: #afadb9;
	--toggleColor: #e7e7ed;
	--disabledBlock: #e9ecef;
	--disabledOptions: #dddddd;

	/* Text Colors */
	--primaryTextColor: #191531;
	--mutedTextColor: #71757d;
	--mutedIconColor: #d1d0d6;

	/* Hover */
	--hoverPurple: linear-gradient(
			0deg,
			rgba(127, 109, 242, 0.1),
			rgba(127, 109, 242, 0.1)
		),
		#ffffff;
}
