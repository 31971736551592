.uploadImage {
	width: 100% !important;
}

.uploadImage:hover {
	background-color: var(--toggleColor);
}

.imagePreview {
	height: 75px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

.imageGuide {
	font-size: 1rem;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.imageGuide:hover {
	cursor: pointer;
	background-color: var(--toggleColor);
}
