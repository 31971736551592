.sectorContainer {
	--bs-accordion-border-color: none;
}

.sectorItem {
	background-color: var(--secondaryColor);
	border: none !important;
	margin: 4px;
	border-radius: 8px !important;
}

.sectorTitle {
	font-size: 14px;
	font-weight: 500;
	padding: 8px 12px;
}

.sectorTitle:not(.collapsed) {
	background-color: transparent;
	border-color: transparent;
	color: var(--primaryTextColor);
}

.sectorProperties {
	padding: 8px 12px;
	font-weight: 400 !important;
	font-size: 14px !important;
}

.accordion-button::after {
	background-image: none;
	background-color: white;
}
