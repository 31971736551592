.gjs-sm-field.gjs-sm-composite {
	border: none;
	background: inherit;
}

.gjs-field {
	background: white;
	border-radius: 8px;
}

.gjs-sm-field input,
.gjs-clm-select input,
.gjs-clm-field input,
.gjs-sm-field select,
.gjs-clm-select select,
.gjs-clm-field select {
	color: inherit !important;
}

.gjs-field-checkbox {
	display: block;
	border-radius: 3px;
}

.gjs-field-checkbox input:checked + .gjs-chk-icon {
	border-color: var(--primaryTextColor);
}

.gjs-field-units {
	color: var(--mutedIconColor);
	pointer-events: none;
}

.gjs-field option {
	color: var(--primaryTextColor);
	font-size: 14px;
	background-color: white;
}
