.topPanelContainer {
	height: var(--topPanelHeight);
	background-color: var(--secondaryColor);
}

.screenName {
	border-color: transparent;
}

.backBtn {
	border: transparent;
}

.saveBtn,
.saveBtn:hover {
	background-color: var(--primaryColor);
	border-color: var(--primaryColor);
}

.saveBtn:hover {
	opacity: 0.9;
}

.zoomLevelInput {
	width: 60px;
}

.fullScreen {
	width: 16px;
	height: 16px;
}
