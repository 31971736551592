.gjs-mdl-container {
	font-family: var(--primaryFont);
}

.gjs-mdl-header {
	background-color: white;
}

.gjs-mdl-content {
	background-color: var(--secondaryColor);
}
