.gjs-am-assets-cont,
#gjs-am-title {
	background-color: var(--secondaryColor);
}

.gjs-btn-prim {
	width: auto;
	color: white;
	background-color: var(--primaryColor);
	border-radius: 8px;
}

.gjs-am-file-uploader > form {
	background: none;
}
