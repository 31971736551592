.gjs-trt-traits {
	border-radius: 8px !important;
	margin: 4px !important;
}

[class$='-custom-trait-type'] {
	display: block;
	background: inherit !important;
}

.gjs-trt-traits,
[class$='-custom-trait-type'] {
	font-size: 14px !important;
	font-family: var(--primaryFont) !important;
}

[class$='-custom-trait-type'] .gjs-label-wrp {
	width: 100%;
}
