@import url('https://fonts.googleapis.com/css2?family=Mukta&display=swap');

@import 'bootstrap/dist/css/bootstrap.min.css';

@import './globals/styles/_fonts.css';
@import './globals/styles/_colors.css';
@import './globals/styles/_heights.css';
@import './globals/styles/_grapes-js.css';
@import './globals/styles/_bootstrap.css';

body {
	margin: 0;
	background-color: var(--secondaryColor);
	color: var(--primaryTextColor);
	font-family: var(--primaryFont) !important;
}

.horizontal-line,
.vertical-line {
	height: 75%;
	border: 1px solid var(--lightGrayColor);
}

.toggle-btn {
	background-color: var(--toggleColor);
	width: fit-content !important;
	height: fit-content !important;
}

.reset-children-margin > * {
	margin: 0 !important;
}

/* Style Scrollbar */
::-webkit-scrollbar {
	width: 7px;
}

::-webkit-scrollbar-track {
	background: transparent;
}

::-webkit-scrollbar-thumb {
	border-radius: 26px;
	background: var(--lightGrayColor);
}

::-webkit-scrollbar-thumb:hover {
	background: var(--grayColor);
}
