.gjs-one-bg {
	background-color: var(--secondaryColor) !important;
}

.gjs-two-color {
	color: var(--primaryTextColor) !important;
}

.gjs-four-color {
	color: var(--primaryColor) !important;
}
