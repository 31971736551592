.tabContainer {
	height: 100vh;
	overflow: hidden;
	position: sticky;
	background-color: white;
	top: 0;
}

.tab {
	height: var(--topPanelHeight) !important;
	border-bottom: 1px solid var(--secondaryColor);
}

.tabContentContainer {
	overflow-y: auto;
	height: calc(100vh - var(--topPanelHeight));
}

.tabBtn {
	border: none;
	background-color: transparent;
}
