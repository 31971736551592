/*Add custom style to override bootstrap global styles*/
/* You should start your selection with custom- */

.custom-nav-link {
	color: var(--mutedTextColor);
}

.custom-nav-link:hover {
	color: var(--primaryColor);
}

.custom-nav-link.active {
	color: var(--primaryColor);
}

.no-glow:focus {
	border-color: transparent;
	box-shadow: none;
	outline: none;
}

.inherit-fw {
	font-size: inherit !important;
}

.no-resize {
	resize: none;
}

.disabled {
	background-color: var(--disabledBlock) !important;
	pointer-events: none !important;
	opacity: 1 !important;
}

.no-input-arrows::-webkit-outer-spin-button,
.no-input-arrows::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/** Custom Checkbox **/
input[type='checkbox'] {
	position: relative;
	border: 2px solid var(--primaryColor);
	border-radius: 2px;
	background: white;
	cursor: pointer;
	line-height: 0;
	margin: 0 0.6em 0 0;
	outline: 0;
	padding: 0 !important;
	vertical-align: text-top;
	height: 20px;
	width: 20px;
	opacity: 0.5;
}

input[type='checkbox']:hover {
	opacity: 1;
}

input[type='checkbox']:checked {
	background-color: var(--primaryColor);
	border: none;
	opacity: 1;
}

input[type='checkbox']:not(:checked) {
	border: 2px solid var(--primaryColor);
}

label {
	color: rgba(117, 115, 131, 1);
	letter-spacing: 0.14px;
	line-height: 18px;
}

hr {
	color: #d1d0d6;
}

.form-control {
	line-height: 24px;
	color: rgba(99, 95, 116, 1);
}

.btn-close {
	border: 1px solid var(--mutedTextColor);
	border-radius: 100%;
	background-size: 9px;
	width: 24px;
	height: 24px;
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNMTQuNjU2OSA5LjcwNzExTDEzLjk0OTcgOUwxMS44Mjg0IDExLjEyMTNMOS43MDcxMSA5TDkgOS43MDcxMUwxMS4xMjEzIDExLjgyODRMOSAxMy45NDk3TDkuNzA3MTEgMTQuNjU2OUwxMS44Mjg0IDEyLjUzNTVMMTMuOTQ5NyAxNC42NTY5TDE0LjY1NjkgMTMuOTQ5N0wxMi41MzU1IDExLjgyODRMMTQuNjU2OSA5LjcwNzExWiIgZmlsbD0iIzc1NzM4MyIvPgo8L3N2Zz4=');
	background-repeat: no-repeat;
	background-size: 35px;
	background-position: center;
}

.accordion {
	--bs-accordion-btn-icon-width: 9px;
	--bs-accordion-btn-active-icon: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNiA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNiAwTDMgNEwwIC0yLjYyMjY4ZS0wN0w2IDBaIiBmaWxsPSIjOEM4QTk3Ii8+Cjwvc3ZnPgo=');
	--bs-accordion-btn-icon: var(--bs-accordion-btn-active-icon);
}
.events-disabled {
	pointer-events: none;
	background: var(--disabledOptions) !important;
}
