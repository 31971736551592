.selectedValue {
	cursor: pointer;
}

.selectedValue::after {
	content: '';
	background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI0IiB2aWV3Qm94PSIwIDAgNiA0IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNiAwTDMgNEwwIC0yLjYyMjY4ZS0wN0w2IDBaIiBmaWxsPSIjOEM4QTk3Ii8+Cjwvc3ZnPgo=');
	width: 8px;
	display: block;
	height: 8px;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	right: 10px;
	top: 15px;
}

.selectList {
	width: 100%;
	top: 100%;
	display: none;
	list-style: none;
	position: absolute;
	z-index: 9;
	overflow-y: auto;
	height: auto;
	max-height: 150px;
}

.selectList li {
	background-color: white;
	padding: 6px;
	min-height: 36px;
}

.selectList li:hover {
	background: var(--hoverPurple);
	cursor: pointer;
}
