.gjs-cv-canvas {
	width: 100% !important;
	height: auto !important;
	top: 0 !important;
	background-color: var(--secondaryColor) !important;
}

.gjs-editor-cont {
	width: 1280px !important;
	height: 800px !important;
}
