.tooltipContent {
	display: none;
	color: white;
	position: absolute;
	padding: 5px;
	border-radius: 6px;
	max-width: 200px;
	font-size: 12px;
	z-index: 10;
	background-color: var(--primaryTextColor);
}

.tooltipContent::after {
	content: '';
	position: absolute;
	border-style: solid;
	bottom: 100%;
	right: 5%;
	margin-left: -5px;
	border-width: 5px;
	transform: rotate(180deg);
	border-color: var(--primaryTextColor) transparent transparent transparent;
}
