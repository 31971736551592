.autocompleteListContainer {
	padding: 0;
	width: 250px;
	max-height: 150px;
	background-color: white;
	overflow-y: auto;
	list-style: none;
	display: none;
	margin: 5px auto;
	position: absolute;
	z-index: 3;
}

.autocompleteListContainer li {
	padding: 6px;
	min-height: 36px;
}

.autocompleteListContainer li:not(.disabledListItem):hover {
	padding: 6px;
	min-height: 36px;
	background: var(--hoverPurple);
	cursor: pointer;
}

.disabledListItem {
	pointer-events: none;
	opacity: 0.6;
}
