.tag {
	all: unset;
	border-radius: 4px;
	background: #7f6df226;
	color: #454259;
	word-break: break-word;
}

.icon {
	color: #a2a0ad;
}
