.gjs-block-category {
	width: 95%;
	border: none !important;
	font-family: var(--primaryFont) !important;
}

.gjs-block-category .gjs-title,
.gjs-blocks-cs {
	background-color: white !important;
}

.gjs-block-category .gjs-title {
	border-bottom: var(--lightGrayColor) 1px solid;
}

.gjs-block {
	padding: 0;
	background-color: white !important;
	position: relative !important;
}

.gjs-block__media {
	background-color: var(--secondaryColor);
	color: transparent !important;
	overflow: hidden;
	padding: 20% 20% 40% 20%;
	height: 66px;
}

.gjs-block-label {
	font-family: var(--primaryFont) !important;
	font-weight: var(--grapesLabelFontWeight);
	font-size: var(--grapesLabelFontSize);
	color: var(--mutedTextColor);
	line-height: 18px;
	letter-spacing: 0.01em;
	margin-bottom: 8px;
}

.gjs-block:hover .gjs-block-label {
	color: var(--primaryTextColor);
}
