.editorSpace {
	position: relative;
	width: 100%;
	height: 100%;
	display: grid;
	align-items: center;
	justify-items: center;
	overflow: auto;
}

.topPanel,
.leftPanel,
.rightPanel {
	position: relative;
	z-index: 2;
}

.topPanel {
	min-width: 680px;
}

.leftPanel,
.rightPanel {
	width: 15%;
	z-index: 3;
	min-width: 290px;
}
